<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="paragraph"></v-skeleton-loader>
        <div v-else>
            <v-toolbar dense class="tc3-title">
                <v-toolbar-title>Companies : Applied Company</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid>

                <v-row dense>
                    <v-col cols="12" sm="4" v-for="item in companyregistrationclosedwithapplied_list" :key="item">
                        <v-card style="margin: 5px; height: 87%;" class="card-css1">


                            <div :style="item.docUrl" class="p1">

                            </div>

                            <div class="text-left">
                                <div class="pl-4 overflow-text-dot pt-3 text-capitalize">
                                    <b class="text-capitalize"><v-tooltip activator="parent">
                                        </v-tooltip> {{ item.company_code }} :: {{ item.company_name }}</b>
                                        
               
                                </div>


                                <v-card-text>

                                    <!-- <v-row class="text-center">
                                        <v-col cols="12" sm="6" md="6">
                                            <div class="text-subtitle-2">Company Code: </div>
                                        </v-col>


                                        <v-col cols="12" sm="6" md="6">
                                            <div class="text-subtitle-2">
                                                <b>{{ item.company_code }}</b>
                                            </div>
                                        </v-col>

                                    </v-row> -->
                                    <v-row class="text-center">
                                        <v-col cols="12" sm="6" md="6">
                                            <div class="text-subtitle-2">Company Type :</div>
                                        </v-col>


                                        <v-col cols="12" sm="6" md="5">
                                            <div class="text-subtitle-2 ">
                                                <b> {{ item.company_type }}<v-tooltip
                                                        activator="parent">
                                                    </v-tooltip></b>
                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row class="text-center">
                                        <v-col cols="12" sm="6" md="5">
                                            <div class="text-subtitle-2">Start Date :</div>
                                        </v-col>


                                        <v-col cols="12" sm="6" md="7">
                                            <div class="text-subtitle-2 ">
                                                <b> {{ item.company_reg_start_date_start_time }}<v-tooltip
                                                        activator="parent">
                                                    </v-tooltip></b>
                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row class="text-center">
                                        <v-col cols="12" sm="6" md="5">
                                            <div class="text-subtitle-2">End Date :</div>
                                        </v-col>


                                        <v-col cols="12" sm="6" md="7">
                                            <div class="text-subtitle-2 ">
                                                <b> {{ item.company_reg_end_date_end_time }} <v-tooltip activator="parent">
                                                    </v-tooltip></b>

                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row class="text-center">
                                        <v-col cols="12" sm="5" md="6">
                                            <div class="text-subtitle-2">Applied Date :</div>
                                        </v-col>


                                        <v-col cols="12" sm="6" md="5">
                                            <div class="text-subtitle-2 ">
                                                <b> {{ item.company_registration_date }} <v-tooltip activator="parent">
                                                    </v-tooltip></b>

                                            </div>
                                        </v-col>

                                    </v-row>
                                    <br>



                                    <center v-if="item.display_msg == 'Applied Button'">
                                        <span>
                                            <span style="color:black;font-weight: bold;">
                                                <v-btn color="green" @click="opendialog()">Upload Offer
                                                    Letter</v-btn><br>

                                            </span></span>
                                            <v-btn icon color="pink" @click="dialogDelete(item.company_offering_id)" >
                                                <v-icon>mdi mdi-close-circle</v-icon>
                                             </v-btn>
                                           
                                    </center>
                                    <center v-else>
                                        <b><strong class="green--text text--lighten-1">{{ item.display_msg }}
                                            </strong></b>
                                        <v-icon left style="color: green">mdi
                                            mdi-checkbox-marked-circle</v-icon>
                                            <v-btn icon color="pink" @click="dialogDelete(item.company_offering_id)" >
                                                <v-icon>mdi mdi-close-circle</v-icon>
                                             </v-btn>
                                    </center>
                                    
                                    <br>

                                    <div class="text-center">
                                        <v-dialog v-model="dialog2" persistent max-width="650px">
                                            <v-card>
                                                <v-card-title class="c-title">
                                                    <v-row justify="space-between">
                                                        Offer Letter Upload
                                                        <v-btn icon dark @click="dialog2 = false">
                                                            <v-icon color="black">mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="12">
                                                                <label style="font-size: 15px;">Upload Offer Letter
                                                                    <span class="required" style="color:red">*</span>
                                                                    :</label><br><br>
                                                                <v-file-input style="width: 350px;" type="file"
                                                                    placeholder="Select Offer Letter file"
                                                                    prepend-icon="mdi-file" ref="file"
                                                                    accept=".pdf,.jpeg,.png" show-size
                                                                    v-model="savedata.file" clearable
                                                                    height="50px"></v-file-input>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col>
                                                                <v-btn style="float: right; margin-left: 10px;"
                                                                    color="primary darken-1" outlined text
                                                                    @click="dialog2 = false">Cancel</v-btn>
                                                                <v-btn :loading=loading1 style="float: right"
                                                                    color="primary darken-1" dark
                                                                    @click="submitFile(item)">save</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </div>

                                    <v-row justify="space-between" >
                                                <span style="color:black;font-weight: bold;">
                                                    <v-btn color="primary"
                                                        @click="viewRoundinfo(item.company_offering_id)"><v-icon>mdi-map-marker-path</v-icon></v-btn>
                                                </span>
                                            <span class="button" v-if="item.isallanswred == false">
                                                <v-btn color="primary" dense      @click="newQuestionDialog(item)" ><v-icon>mdi-draw-pen</v-icon></v-btn>
                                            </span>
                                            <v-btn color="primary" @click="finddetails(item)" v-model="details_id" dense>
                                                <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                                            </v-btn>
                                       
                                    </v-row>
                                </v-card-text>
                                <br />

                                <br />
                            </div>



                        </v-card>

                    </v-col>
                </v-row>

            </v-container>
            <div class="text-center">
                <v-dialog v-model="dialog1" width="600">
                    <v-card>
                        <v-card-title class="card-title">
                            <v-row justify="space-between">
                                Student Company Round Details
                                <v-btn icon dark @click="dialog1 = false">
                                    <v-icon class="mr-2  x-large" color="error">mdi-close-circle-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <template v-if="company != null">

                                <v-row>
                                    <v-col md="4" sm="6">
                                        <span class="lbl"> Company Name </span>
                                    </v-col>
                                    <v-col md="4" sm="6">
                                        :{{ company.name }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" sm="6">
                                        <span class="lbl"> Domain </span>
                                    </v-col>
                                    <v-col md="4" sm="6">

                                        : <span v-for="(i, index) in company.industry" :key=index> {{ i }}<br></span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="4" sm="6">
                                        <span class="lbl"> Offering</span>
                                    </v-col>
                                    <v-col md="4" sm="6">

                                        : {{ company.placementtype }}
                                    </v-col>
                                </v-row>

                            </template>



                            <v-row>
                                <v-col cols="12" sm='6' v-for="item in studentround_list" :key="item">
                                    <v-card>
                                        <v-card-title class="c-title">
                                            Round {{ item.round_number }} : {{ item.round_details }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="6" sm="4">
                                                    <span class="lbl"> Result</span>
                                                </v-col>
                                                <v-col cols="6" md="6">
                                                    : <span v-if="item.round_isfinal == 'Pass'"
                                                        style="color:green;font-weight:bold;">
                                                        {{ item.round_student_status }}
                                                    </span>
                                                    <span v-else style="color:red;font-weight:bold;">
                                                        {{ item.round_student_status }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-row justify="center">
                                    <div style="margin-top:40px;margin-bottom:10px"
                                        v-if="this.finalselected == 'finalselected'">
                                        <v-card>
                                            <v-card-title class="c-title">
                                                You Are Selected
                                            </v-card-title>
                                        </v-card>
                                    </div>
                                </v-row>
                                <br>
                            </v-row>

                            <v-row justify="center">
                                <v-btn class="loginew" color="white" outlined text @click="dialog1 = false">Close</v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <div>


                <br>

                <br>
            </div>
        </div>
        <v-row justify="center">
          <v-dialog v-model="newquestion_dialog" persistent max-width="600">
            <v-card>
              <v-card-title class="card-title">Answer Questions<v-spacer></v-spacer> <v-icon class="mr-2  x-large"
                  @click="newquestion_dialog = false" color="error">mdi-close-circle-outline</v-icon> </v-card-title>
              <!-- <input type="hidden" v-model="dataItem" /> -->
              <v-spacer></v-spacer>


              <span v-for="(i, index) in offeringnew_questions_list" :key=index>
                <v-row max-width="555">
      <v-col cols="10" sm="10" class="mx-auto">
        <strong>Q{{ index + 1 }}:</strong> 
        <span>{{ i.question }}</span><br>
        <span style="font-size: 12px;"><b>Q. Remark: </b>{{ i.remark }}</span>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ i.question }}</span>
          </template>
          <span>{{ i.remark }}</span>
        </v-tooltip> -->
      </v-col>
    </v-row>
                
                <v-row
                  v-if="i.question_multichoice != true && i.question_choice != true && i.is_attachmentrequired != true && i.is_attachment_with_description != true"
                  max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" placeholder="Enter Your Answer" outlined :hint="i.remark" clearable required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachment_with_description == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto">
                    <v-text-field v-model="i.descrAnswer" style="margin-top:-5px" placeholder="Enter Your Answer"
                      clearable outlined :hint="i.remark" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-50px">
                    <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                      prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                      v-model="i.answer_file" :hint="i.remark" ></v-file-input>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.is_attachmentrequired == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row><br />
                      <v-file-input type="file" style="margin-top:30px;" placeholder="Select Attachment"
                        prepend-icon="mdi-file" ref="file" show-size required="true" clearable
                        v-model="i.answer_file" :hint="i.remark" ></v-file-input>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_multichoice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row v-for="(role, index) in i.optionlist" :key="index">
                        <v-checkbox color="success" :hint="role.option_remark" persistent-hint v-model="role.isSelectedOP" >
                            <template #label>
                                {{ role.answer }}
                            </template>
                        </v-checkbox>
                     
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-else-if="i.question_choice == true" max-width="555">
                  <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                    <v-row>
                      <v-col cols="10" sm="9">
                        <!-- <v-radio-group row v-model="i.singleanswer">
                          <v-radio v-for="n in i.optionlist" :key="n" :label="` ${n.answer}`" :value="n" :hint="i.remark" ></v-radio>
                        </v-radio-group> -->
                        <span  v-for="n in i.optionlist" :key="n">
                            <v-radio-group row v-model="i.singleanswer">
                              <v-radio :label="`${n.answer}`" :value="n" :hint="n.option_remark"></v-radio>
                            </v-radio-group>
                            <span style="font-size: 12px;"><b>Remark: </b>{{ n.option_remark }}</span>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                  


              </span>
              <v-card-actions class="d-flex-center ma-auto">
                <v-spacer></v-spacer>
                <v-row>
                  <!-- <v-btn elevation="2" raised class="logi" text @click="closequestion()">cancel</v-btn> -->
                  <v-btn color="indigo darken-4" dark class="mb-2 loginew"
                    @click="savenewQuestions(offeringnew_questions_list)">Submit</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
            
                <v-dialog v-model="dialog_delete"  max-width="600">
                    <v-card>
                        <v-card-title class="del-card-title">Send Otp For Delete Application<v-spacer></v-spacer> <v-icon class="mr-2  x-large"
                            @click="dialog_delete = false" color="error">mdi-close-circle-outline</v-icon></v-card-title>
                       
                   <center>
                      <div class="red--text"> After clicking Send Otp Button .Otp Will Be Send Your Register Email ID.  </div>
                      <v-text-field
                      class="mx-5"
                      outlined
                        id="enterOTP"
                        v-if="hidden1"
                        v-model="otp"
                        :rules="[v => !!v || 'OTP is required']"
                        prepend-icon="mdi-email-open"
                        label="OTP"
                        min="1"
                        max="6"
                        clearable
                        hint="Please check your mail & enter otp."
                        required
                      ></v-text-field>
                      <v-btn :loading='overlay' v-if="!hidden1" class="ma-4 loginew" color="primary" @click="sendotp">
                        <v-icon>mdi-email-send-outline</v-icon>&nbsp;&nbsp; Send OTP
                      </v-btn>
                      
                        <v-btn v-if="hidden1"
                          class="ma-4"
                          color="primary"
                          @click="deletedata()"
                         
                        >Verify Otp And Delete Application</v-btn>
                   </center>
                    </v-card>
                </v-dialog>

    </div>
</template>


<script>
import axios from "axios";

export default {
    data: () => ({
        otp: "",
        hidden1:false,
        overlay:false,
        offeringnew_questions_list: [],
        loading: false,
        loading1: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        load: false,
        details_id: "",
        delete_id: "",
        delete_company_offering_id: "",
        dialog_delete: false,
        dialog1: false,
        dialog2: false,
        companyregistrationclosedwithapplied_list: null,
        company: null,
        savedata: {
            file: null,
            company_name: '',
        },
        studentround_list: [],
        finalselected: "",
        headers: [
            {
                text: "Company",
                value: "name",
            },
            {
                text: "Company Type",
                value: "companytype",
            },
            {
                text: "Scheduled Date",
                value: "from_schedule_date",
            },
            {
                text: "Domain",
                value: "industry",
            },
            {
                text: "Offering",
                value: "placementtype",
            },
            {
                text: "Internship Type",
                value: "internshiptype",
            },
            {
                text: "Branches",
                value: "branches",
            },
            {
                text: "Institutes",
                value: "institutes",
            },
            {
                text: "Know More",
                value: "details",
            },
            {
                text: "Apply",
                value: "apply",
            },
        ],
        defaultItem: {
            id: "",
        },
        deleteItem: {
            id: "",
        },
        newquestion_dialog: false,
        offering_company: "",
    }),
    computed: {},
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        dialogDelete(item){
            this.dialog_delete = true;
            this.delete_id = item;
            this.hidden1=false;
            this.overlay=false;
            this.otp="";
        },
        deletedata() {
            if(this.otp=="")
            {
                this.showSnackbar("red", "Please Enter OTP.");
                return;
            }
                var params = {
                    otp:this.otp,
                    delete_id: this.delete_id,
                };
                this.dialog_delete = false;
                axios.post("/TPOCompanyScheduling/deleteCompanyOff", params).then((res) => {
                    if (res.data.msg == "200") {
                            
                            this.showSnackbar("#4caf50", res.data.message);
                            this.init();
                            
                    }
                    else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => { });
            },
        sendotp() {
          this.overlay = true;
          axios
            .post("/login/sendOtpforDeleteApplication")
            .then(res => {
              if (res.data.msg == "200") {
                this.overlay = false;
                this.showSnackbar("#4caf50", "OTP has been sent on your Email..."); 
                this.hidden1 = true;
              }else{
                 this.showSnackbar("red", res.data.msg );
                 this.overlay = false;
              }
            })
          
    },
        savenewQuestions(questionItem) {
      console.log(" save new");
  console.log(questionItem)
      var count = 0;
      for (var k in questionItem) {
        console.log("in offering ")
        if (questionItem[k].question_choice == true) {
          if (questionItem[k].singleanswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (questionItem[k].is_attachmentrequired == true) {
          if (questionItem[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer...");
            return;
          }
        }
        if (questionItem[k].is_attachment_with_description == true) {
          console.log("file");
          console.log(questionItem[k].answer_file);
          if (questionItem[k].answer_file == null) {
            this.showSnackbar("#b71c1c", "Attach file as an answer ...");
            return;
          } if (questionItem[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions..");
            return;
          }
        }
        if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true) {
          // console.log("hiii")
          if (questionItem[k].descrAnswer == "") {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
        if (questionItem[k].question_multichoice == true) {
          console.log("in multi")
          console.log(count)
          for (var a in questionItem[k].optionlist) {
            console.log(questionItem[k].optionlist[a].isSelectedOP)
            if (questionItem[k].optionlist[a].isSelectedOP == true) {
              count++;
            }
          }
          console.log(count);
          if (count == 0) {
            this.showSnackbar("#b71c1c", "Answer All Questions...");
            return;
          }
        }
      }
      let optionlistnew = []
      let formData = new FormData();
      formData.append("size", questionItem.length)
      formData.append("companyOfferingid", this.offering_company.company_offering_id)
      for (var k in questionItem) {
        formData.append("id" + k, questionItem[k].id);
        if (questionItem[k].question_choice == true) {
          formData.append("singleanswer" + k, JSON.stringify(questionItem[k].singleanswer));
        }
        else if (questionItem[k].is_attachmentrequired == true) {
          formData.append("answer_file" + k, questionItem[k].answer_file);
        }
        else if (questionItem[k].is_attachment_with_description == true) {
          formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
          formData.append("answer_file" + k, questionItem[k].answer_file);
        } else if (questionItem[k].question_multichoice == true) {

          for (var a in questionItem[k].optionlist) {
            console.log(questionItem[k].optionlist[a].isSelectedOP)
            if (questionItem[k].optionlist[a].isSelectedOP == true) {
              optionlistnew.push(questionItem[k].optionlist[a])
            }
          }
          formData.append("optionlist" + k, JSON.stringify(optionlistnew));
        } else if (questionItem[k].question_choice != true && questionItem[k].question_multichoice != true && questionItem[k].is_attachmentrequired != true && questionItem[k].is_attachment_with_description != true) {
          formData.append("descrAnswer" + k, questionItem[k].descrAnswer);
        }
        // formData.append("question"+k, this.offering_questions_list[k].question);
      }
      axios
        .post("/StudentApplicationTrack/savenewQuestions", formData)
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          if (res.data.msg == "success") {
            this.newquestion_dialog = false;
            this.offering_company.isallanswred=true;
            this.showSnackbar("#4caf50", "Answers Saved Successfully...");
          } else {
            this.showSnackbar("#b71c1c", res.data.errormsg);
            this.question_dialog = false;
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
        newQuestionDialog(item) {
            this.offering_company=item;
      const formData = {
        companyOfferingid: item.company_offering_id,
      };
      axios
        .post("/StudentApplicationTrack/getNewOfferingQustion", formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "success") {
            this.offeringnew_questions_list = res.data.offeringnew_questions_list

            this.newquestion_dialog = true;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          // window.alert(error)
        })
    },
        opendialog() {
            this.dialog2 = true;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        init() {
            this.loading = true;
            axios
                .post("/TPOCompanyScheduling/schedulesdcopaniesappliedonly")
                .then((res) => {
                    if (res.data.msg == "200") {

                        this.companyregistrationclosedwithapplied_list = res.data.companyregistrationclosedwithapplied_list;
                        this.loading = false;
                    } else if (res.data.msg == "201") {
                        this.showSnackbar("#b71c1c", res.data.error);
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong...");
                    }
                })

        },
        viewRoundinfo(item) {
            const formData = {
                companyOfferingid: item,
            };

            axios
                .post("/StudentApplicationTrack/appliedRoundInfo", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.msg == "200") {
                        this.company = res.data.company_list;
                        this.studentround_list = res.data.studentround_list;

                        this.dialog1 = true;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => { });
        },
        finddetails(item) {

            this.$store.commit("setcompany_id", item.company_offering_id);

            this.$router.push({ name: "company-info" });
        },
        submitFile(item) {
            this.loading1 = true;
            let formData = new FormData();
            formData.append('file', this.savedata.file);
            formData.append('company_offering_id', item.company_offering_id);
            axios
                .post("/TPOCompanyScheduling/saveOfferletterForAppliedCompany", formData)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.status == "200") {
                        this.init();
                        this.showSnackbar("green", res.data.msg);
                        this.dialog2 = false;
                        this.loading1 = false;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg); // show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => { });

        },

      

       

    }
};
</script>
<style scoped>
.ec-title {
    background-image: linear-gradient(-100deg, #f4faba, #d64907);
    color: #fff;
}

.c-title {
    background-image: linear-gradient(-90deg, #befcf7, #4b7cbb);
    color: #fff;
}

.c1-title {
    background-image: linear-gradient(-90deg, #e251b2, #94038a);
    color: #fff;
}

.c2-title {
    background-image: linear-gradient(-90deg, #f97979, #c82950);
    color: #fff;
}

.c3-title {
    background-image: linear-gradient(-90deg, #a3a0d4, #47ddba);
    color: #fff;
}

.c4-title {
    background-image: linear-gradient(-90deg, #73a47c, #03942c);
    color: #fff;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}

.tc1-title {
    background-image: linear-gradient(-90deg, #fff, #960596);
    color: #fff;
    border-radius: 3px;
}

.tc2-title {
    background-image: linear-gradient(-90deg, #fff, #960505);
    color: #fff;
    border-radius: 3px;
}

.tc3-title {
    background-image: linear-gradient(-90deg, #fff, #074f8a);
    color: #fff;
    border-radius: 3px;
}

.tc4-title {
    background-image: linear-gradient(-90deg, #fff, #034824);
    color: #fff;
    border-radius: 3px;
}

.image-css {
    position: absolute !important;
    opacity: 0.3;

}

.text-over {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  height: 22px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-css1 {
    background: radial-gradient(circle,
            rgb(160, 240, 216) 0%,
            rgb(224, 242, 224) 100%) !important;
    /* background:radial-gradient(circle, rgba(254,235,243,1) 0%, rgb(220, 217, 217) 100%); */
    margin-left: 10px;
    border-radius: 10px !important;
    ;
    /* border-radius: 14px  */
    /* position: relative; */
}

.logi {
    background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
    color: #fff;
}

@media only screen and (max-width: 600px) {

    .v-card__subtitle,
    .v-card__text {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 0.375rem;
        letter-spacing: 0.0071428571em;
    }

}

.card-title {
    background: linear-gradient(to top right, rgba(49, 49, 69, 0.5), rgba(27, 132, 167, 0.5)), 
    url("~@/assets/lapt.jpg");
    background-size: cover; /* Optional: Adjust background size as needed */
    background-position: center; /* Optional: Adjust background position as needed */
    color: #fff;
}

.lbl {
    color: #1705e6;
}

.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.p1 {
    background-size: 100%;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.1;
}

/* .p1::before {
    
    opacity: 1;
} */


.edit-v-icon:hover {
    color: white;
}

.downbtn {
    float: right;
}

.logi1 {
    background-image: linear-gradient(-100deg, #6cf389, #0b9761);
    color: #fff;
}

.button {
    background-color: #000080;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 13px;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;

    animation: blinking 1s infinite;
}

@keyframes blinking {
    0% {
        background-color: #ff3d50;

    }

    /* ADD MORE KEYFRAMES IF YOU WANT
  50% {
    background-color: #55d66b;
    border: 5px solid #126620;
  } */
    100% {
        background-color: #222291;
    }
}

.del-card-title{
    background: linear-gradient(to top right, rgba(49, 49, 69, 0.5), rgba(27, 132, 167, 0.5)), 
    url("~@/assets/lapt.jpg");
    background-size: cover; /* Optional: Adjust background size as needed */
    background-position: center; /* Optional: Adjust background position as needed */
    color: #fff;
}

.loginew {
    background: -webkit-gradient(linear, left bottom, left top, from(#09203f), to(#537895));
    background: linear-gradient(to top, #09203f 0%, #537895 100%);
}
</style>